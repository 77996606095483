/*
 @Author: Finelayers
 ver: 0.1
 This file contains the styling for the actual theme, this
 is the file you need to edit to change the look of the
 theme.
 However we used Less script to make things easy to maintain, this is the compressed ourput, we recommend to edit the less files and then recombile the files and add the result here, if you don't like to use less you can edit this file here.
 
 This files contents are outlined below.
 00.    Variables (SASS)
 01.	Theme Reset Style
 02.	Global Elements
 03.	Home Page 1
			+Top Bar
			+Header
			+Main Content
			+Footer
 04.	Practice Areas v1 
 05.	Practice Areas v2
 06.	Practice Areas v3
 07.	Practice single
 08.	Practice single
 09.	Attorney Detail
 10.	Attorney v1
 11.	Attorney v2
 12.	Blog List
 13.	Blog Grid
 14.	About Us
 15.	Contact
*/

/****** Variables *********/

$primary-color: #b6d884;
$secondary-color: #88B24B;
$secondary-color-dark: #375012;
$white: #fff;
$grey: #ccc;
/****** Theme Reset Style ******/

html {
  min-width: 320px;
}

body {
  background: $white;
  color: #777;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #222;
  margin: 0 0 20px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #222;
}

p a,
.top-bar a,
.contact-holder a {
  color: #white;
  display: inline;
}

.top-bar a:hover,
.contact-holder a:hover {
  color: $secondary-color-dark;
  text-decoration: underline;
}

p a:hover {
  text-decoration: underline;
  outline: none;
}

p {
  letter-spacing: normal;
  margin-bottom: 20px;
  line-height: 24px;
  font-size: 14px;
  color: #777;
}

a {
  color: #7f7f7f;
  text-decoration: none;
  outline: none;
  display: block;
}

a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
  color: $white;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.border-0 {
  border: 0 !important;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
  width: 100%;
}

blockquote {
  padding: 0;
  border: 0;
}

label {
  color: #7f7f7f;
  display: block;
  font-weight: 400;
  margin: 0;
}

textarea {
  border-radius: 0 !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input,
.form-control {
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 0;
  background: $white;
  line-height: 50px;
  box-shadow: none !important;
  padding: 0 20px;
  font-weight: 600;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.form-control::-moz-placeholder {
  color: #666;
}

.form-control::-webkit-input-placeholder {
  color: #666;
}

.form-control:focus::-moz-placeholder {
  color: #666;
}

.clear {
  clear: both !important;
}

hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, $white 0%, #000000 50%, $white 90%);
}

.x-form-item.modx-tv {
  padding: 0 !important;
}

.sub-footer a {
  display: inline;
}

/****** Global Elements ******/

h1,
h2 {
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
}

h3 {
  font-size: 22px;
  font-weight: medium;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
}

.btn {
  padding: 9px 20px;
  color: $white;
  font-size: 17px;
  font-weight: 600;
  border-radius: 0;
  border-left: 5px solid;
  background: $secondary-color-dark;
}

.btn.claim {
  padding-top: 30px;
  padding-bottom: 13px;
}

.btn:hover {
  background: $secondary-color;
}

.btn.white-bg {
  background: $white;
  color: #444;
}

.btn.white-bg:hover {
  color: $white;
}

.btn.white-bg i {
  color: $white;
}

.btn.icon {
  padding-right: 52px;
  position: relative;
}

.btn.icon i {
  height: 100%;
  position: absolute;
  right: 0;
  padding: 0 10px;
  top: 0;
  line-height: 40px;
}

.btn.read-more {
  background: none;
  color: #444;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}

.btn.read-more:hover {
  color: $white;
}

.main-heading {
  display: inline-block;
  padding: 0 0 40px;
}

.main-heading span {
  display: block;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  line-height: 23px;
  margin: 0 0 10px;
}

.main-heading h2 {
  margin: 0 0 20px 0;
  line-height: 28px;
}

.social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-icons li {
  float: left;
  margin: 0 0 0 10px;
}

.social-icons li:first-child {
  margin: 0;
}

.social-icons li a {
  height: 32px;
  width: 32px;
  line-height: 32px;
  border-radius: 100%;
  border: 1px solid #999;
  color: #999;
  text-align: center;
  display: block;
  font-size: 16px;
}

.social-icons li a:hover {
  background: #0274b3;
  border-color: #0274b3;
  color: $white;
}

/*.tc-breadcrumb ul{ overflow: hidden; padding: 13px 0 0;}
.tc-breadcrumb ul li{ float: left; color: $white;}
.tc-breadcrumb ul li::before{ content: "//"; margin: 0 10px; float: left; color: $white;}
.tc-breadcrumb ul li:first-child::before{ display: none;}
.tc-breadcrumb ul li a{ float: left; color: $white;}*/

ul.B_crumbBox {
  text-align: center;
}

ul.B_crumbBox,
ul.B_crumbBox li a {
  color: $white;
}

ul.B_crumbBox li {
  display: inline-block;
}

ul.B_crumbBox li a:hover {
  color: $primary-color;
}

.pagination-holder {
  line-height: 0;
  text-align: center;
}

.pagination-holder ul {
  display: inline-block;
}

.pagination-holder ul li {
  margin: 0 0 0 5px;
  float: left;
  display: inherit;
}

.pagination-holder ul li:first-child {
  margin: 0;
}

.pagination-holder ul li a {
  color: #444;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 0 !important;
  border: 2px solid;
}

.pagination-holder ul li a:hover {
  color: $white;
}

.pagination-holder ul li:first-child a,
.pagination-holder ul li:last-child a {
  color: $white;
  padding: 10px 15px;
}

.pagination-holder ul li:first-child a:hover,
.pagination-holder ul li:last-child a:hover {
  background: none;
  color: #444;
}

.rating-stars ul li {
  float: left;
  margin: 0 0 0 7px;
}

.rating-stars ul li:first-child {
  margin: 0
}

.rating-stars ul li i {
  color: #f0bf2d;
}

.overlay-dark,
.overlay-pink,
.overlay-gray {
  position: relative;
}

.overlay-dark::before,
.overlay-pink::before,
.overlay-gray::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.overlay-pink::before {
  background: rgba( 244, 86, 97, 0.9);
}

.overlay-gray::before {
  background: rgba( 242, 242, 242, 0.9);
}

.position-center-center {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.position-center-x {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.position-center-y {
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.z-index-2 {
  z-index: 2;
}

.font-playfair {
  font-family: 'Playfair Display', serif;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}

.font-italic {
  font-style: italic
}

.white-bg {
  background: $white;
}

.gray-bg {
  background: #f0f0f0;
}

.text-white {
  color: $white;
}

.font-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.h-white h1,
.h-white h2,
.h-white h3,
.h-white h4,
.h-white h5,
.h-white h6 {
  color: $white;
}

.p-white p {
  color: $white;
}

.h-m-0 h1,
.h-m-0 h2,
.h-m-0 h3,
.h-m-0 h4,
.h-m-0 h5,
.h-m-0 h6 {
  margin: 0;
}

.p-m-0 p {
  margin: 0;
}

.h-white h1 a,
.h-white h2 a,
.h-white h3 a,
.h-white h4 a,
.h-white h5 a,
.h-white h6 a {
  color: $white;
}

.p-white p {
  color: $white;
}

.h-m-0 h1 a,
.h-m-0 h2 a,
.h-m-0 h3 a,
.h-m-0 h4 a,
.h-m-0 h5 a,
.h-m-0 h6 a {
  margin: 0;
}

.p-m-0 p a {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-80 {
  margin-bottom: 90px;
}

.mb-80 {
  margin-bottom: 1000px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-80 {
  margin-top: 90px;
}

.mt-80 {
  margin-top: 100px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-80 {
  padding-bottom: 90px;
}

.pb-80 {
  padding-bottom: 100px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-80 {
  padding-top: 90px;
}

.pt-80 {
  padding-top: 100px;
}

.navbar-brand {
  height: auto;
  width: auto;
  padding: 0;
}

.padding-top {
  padding-top: 70px;
}

.padding-bottom {
  padding-bottom: 70px;
}

.padding {
  padding: 70px 0;
}

.padding-sides {
  padding: 70px 1em;
}

.margin-top {
  margin-top: 70px;
}

.margin-bottom {
  margin-bottom: 70px;
}

.margin {
  margin: 70px 0;
}

.countdown {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  text-align: center;
  overflow: hidden;
}

.countdown li {
  text-align: center;
  float: left;
  margin: 0 0 0 20px;
}

.countdown li:first-child {
  margin: 0;
}

.countdown li {
  background: $white;
  color: #1e181c;
  border-radius: 2px;
  font-size: 30px;
  padding: 5px 20px;
  display: block;
  border: 1px solid;
  overflow: hidden;
}

.countdown li p {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #9b756c;
}

.btn-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-list li {
  float: left;
  margin: 0 0 0 30px;
}

.btn-list li:first-child {
  margin: 0;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tc-select {
  padding: 0 10px;
}

.tc-select option {
  padding: 10px;
}

/****** Home Page 1 ***********************************************************
*********************************************************** Home Page 1 ******/

/****** Top Bar ******/

.top-bar {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.top-bar .btn:hover {
  color: $white;
}

/* site info */

.site-info {
  overflow: hidden;
  padding: 11px 0;
}

.site-info ul li {
  float: left;
  border-left: 1px solid #ccc;
  padding: 0 0 0 14px;
  margin: 0 0 0 14px;
  font-weight: 600;
  color: #999;
}

.site-info ul li:first-child {
  border: 0;
  padding: 0;
  margin: 0;
}

.site-info ul li i {
  margin: 0 10px 0 0;
}

/* Top Right Side */

.top-right-side {
  overflow: hidden;
}

.top-right-side .social-icons {
  padding: 6px 0;
}

.top-right-side .btn {
  margin: 0 10px 0 0;
}

/****** Header ******/

.header {
  padding: 25px 0;
  background: $white;
}

.is-sticky .header {
  width: 100%;
  z-index: 100;
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.29);
}

/* nav */

.nav-holder {
  float: left;
  position: relative;
}

.navbar-collapse {
  padding: 0;
}

.navigation-holder {
  float: right;
}

.tc-nav-list>li {
  float: left;
}

.tc-nav-list>li:first-child {
  margin: 0;
}

.tc-nav-list>li a {
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  padding: 20px 15px;
  position: relative;
}

/* SERVICES NAV */

ul.services_nav li a {
  width: 100%;
  padding: 11px 10px 7px 0;
  text-align: right;
  font-size: 1.5em;
  margin-bottom: 7px;
}

ul.services_nav li {
  transition: all .3s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1em;
}

ul.services_nav li:nth-child(odd) {
  background-color: darken($grey, 20%);
  a {
    color: $white;
  }
  border-left: 8px solid darken($grey, 40%);
}

ul.services_nav li:nth-child(even) {
  background-color: $grey;
  border-left: 8px solid darken($grey, 40%);
  a {
    color: darken($grey, 50%);
  }
}

ul.services_nav li:hover {
  background-color: darken($grey, 40%);
  border-left: 25px solid $primary-color;
  padding-right: 15px;
  a {
    color: $white;
  }
}

/* Dropdown */

.tc-nav-list li>ul {
  list-style: none;
  margin: 0;
  padding: 0;
  top: 80%;
  border-radius: 0;
  position: absolute;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  background: $white;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
}

.tc-nav-list li ul li {
  position: relative;
  float: none;
  border-bottom: 1px solid #e1e1e1;
}

.tc-nav-list li ul li:last-child {
  border: 0;
}

.tc-nav-list li ul li a {
  width: 100%;
  position: relative;
  color: #888;
  padding: 15px;
  text-transform: capitalize;
  border-left: 4px solid;
}

.tc-nav-list li ul li a:hover {
  border-left: 20px solid;
}

.tc-nav-list li ul li a:hover i {
  color: $white;
}

.tc-nav-list li ul li a i {
  color: #666;
  position: absolute;
  right: 10px;
  top: 50%;
  margin: -7px 0 0;
}

.tc-nav-list li:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

/* Sub Menu */

.tc-nav-list li>ul li ul {
  left: 110%;
  top: 0 !important;
}

.tc-nav-list li ul li:hover>ul {
  visibility: visible;
  opacity: 1;
  left: 100%;
}

/* Mega Dropdown */

.mega-dropdown {
  position: static;
}

.mega-dropdown .dropdown-menu {
  width: 100% !important;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.blog-categories {
  padding: 20px;
}

.blog-categories h2 {
  font-size: 16px;
}

.blog-categories ul {
  height: 200px;
}

.blog-categories ul li {
  margin: 0 0 10px;
}

.blog-categories ul li:last-child {
  margin: 0;
}

.mega-blog-slider {
  padding: 20px;
}

.mega-blog-slider .large-post-img {
  margin: 0 0 10px;
}

.mega-blog-slider .large-post-detail.style-3 {
  border: 0;
}

/* Search Field */

.search-field {
  margin: 25px 0 0 20px;
  position: relative;
}

.search-field a {
  padding: 13px;
  border: 2px solid #ccc;
}

.search-box {
  position: absolute;
  top: 150%;
  opacity: 0;
  visibility: hidden;
  width: 200px;
  right: 0;
  z-index: 1;
}

.search-box button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  width: 40px;
  height: 100%;
}

.on-click {
  top: 110%;
  opacity: 1;
  visibility: visible;
}

/* Banner Slider */

.carousel-inner {
  position: relative;
}

.carousel-inner .item>img {
  width: 100%;
}

.carousel-inner .item .caption {
  background-color: rgba(0,0,0,.4);
  padding: 10px 14px;
  border-radius: 10px;
  @media (min-width: 990px) {
    max-width: 60%;
    padding: 3em;
  }
}

.caption h1 {
  font-size: 70px;
}

.delay-1s {
  animation-delay: 0.5s;
}

.delay-2s {
  animation-delay: 0.8s;
}

.delay-3s {
  animation-delay: 0.9s;
}

.slider-nav:hover {
  color: $white;
}

.slider-nav {
  opacity: 0;
  visibility: hidden;
}

#main-slider:hover .slider-nav {
  opacity: 1;
  visibility: visible;
}

#main-slider h2 {
  text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.4);
  font-size: 1.6em;
  @media (min-width: 780px) {
    font-size: 28px;
  }
  @media (min-width: 990px) {
    font-size: 36px;
  }
}

#main-slider p {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .9);
  font-size: 18px;
  line-height: 1.2em;

  @media (min-width: 780px){
    font-size: 24px;
  }
  

}

/****** Main Content ******/

/* Welcome Section */

.welcome-detail {
  margin: 160px 0 0;
  padding: 0 30px 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 4px rgba(119, 119, 119, 0.22);
  -moz-box-shadow: 0px 0px 5px 4px rgba(119, 119, 119, 0.22);
  -webkit-box-shadow: 0px 0px 5px 4px rgba(119, 119, 119, 0.22);
}

.welcome-detail .detail-inner p {
  color: #777;
  font-weight: 600;
}

.blockquote.style-1 {
  margin: 0 0 20px;
  position: relative;
  font-size: 18px;
  color: #555;
  padding: 0 0 0 100px;
  font-weight: bold;
}

.blockquote.style-1::before {
  content: "\f10d";
  font-family: fontawesome;
  position: absolute;
  top: -5px;
  left: 25px;
  font-style: normal;
  font-size: 48px;
  ;
}

.welcome-detail .detail-inner .autrhor-name {
  font-size: 16px;
  font-weight: 600;
}

.welcome-img {
  float: right;
}

.welcome-img img {
  margin: -100px 0 0;
  z-index: -1;
}

/* Facts Section */

.facts-section {
  position: relative;
  padding: 50px 0;
}

.facts-section::before {
  z-index: 1;
  background: rgba( 0, 0, 0, 0.7);
}

.facts-lsit {
  padding: 30px 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: $white;
  margin: 0 30px;
  position: absolute;
  border: 1px solid #e1e1e1;
  top: -70px
}

.facts-lsit ul li {
  float: left;
  text-align: center;
  padding: 0 44px;
  border-left: 1px solid #e1e1e1;
}

.facts-lsit ul li:first-child {
  border: 0;
}

.facts-lsit ul li strong {
  font-size: 40px;
  margin: 0 0 10px;
  display: block;
  color: #222;
}

.facts-lsit ul li span {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.facts-section .btn {
  position: relative;
  z-index: 1;
}

/* Practice Area */

.practice-column:hover .practice-icon {
  color: $white;
}

.services-info::before {
  z-index: 1;
}

.practice-img {
  position: relative;
  overflow: hidden;
}

.our-team .practice-img {
  box-shadow: none;
  transition: all .5s ease-in-out;
}

.our-team .practice-img:hover {
  box-shadow: 9px 10px 0px rgba(0, 0, 0, .2), -9px -10px 0px rgba(0, 0, 0, .2);
}

.practice-img img {
  width: 100%;
}

.overlay-padding {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 20px;
  bottom: -100%;
}

.practice-img .overlay {
  position: static;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
}

.a-link {
  font-size: 20px;
  height: 50px;
  width: 50px;
  margin: -100% 0 0;
  line-height: 50px;
  text-align: center;
  background: $white;
  display: block;
}

.practice-column:hover .practice-img .overlay {
  opacity: 1;
  visibility: visible;
}

.practice-column:hover .overlay-padding {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
}

.practice-column:hover .a-link {
  margin: 0;
}

.practice-column {
  h3 {
    font-size: 1em;
    @media(min-width: 720px) {
      font-size: 1.4em;
    }
  }
  a.read-more {
    font-size: .8em;
    @media(min-width: 720px) {
      font-size: 1em;
    }
  }
}

.practice-icon {
  height: 76px;
  line-height: 76px;
  text-align: center;
  width: 76px;
  background: $white;
  border: 2px solid;
  border-radius: 2px;
  display: block;
  font-size: 44px;
  position: absolute;
  top: -36px;
  left: 10px;
}

.practice-column-btm {
  position: relative;
  padding: 20px 0 0 108px;
}

.practice-column-btm p {
  font-weight: 500;
}

.read-more {
  color: #444;
  font-weight: 500;
  font-size: 16px;
}

.practice-slider .owl-controls {
  text-align: center;
  line-height: 0;
}

.owl-prev,
.owl-next {
  height: 49px;
  width: 49px;
  line-height: 49px;
  text-align: center;
  color: $white;
  border: 1px solid $white;
  cursor: pointer;
  float: left;
  margin: 0 0 0 10px;
  color: $white !important;
}

.practice-slider .owl-nav {
  display: inline-block;
  margin: 30px 0 0;
}

.owl-prev::before,
.owl-next::before {
  font-family: fontawesome;
}

.owl-prev::before {
  content: "\f177";
}

.owl-next::before {
  content: "\f178";
}

.owl-prev:hover,
.owl-next:hover {
  background: $white;
}

/* Practice Area v2 */

.practice-area-v2 .practice-column-btm {
  padding: 10px 10px 0 10px;
}

.practice-icon {
  top: 20px;
  left: 0;
}

.practice-area-v2 h3 {
  margin: 0;
}

.practice-area-v2 p {
  font-size: 13px;
  margin: 0 0 10px;
}

/* Caption Banners */

.caption-banner {
  position: relative;
}

.caption-banner::before {
  z-index: 1;
}

.caption-text {
  z-index: 2;
  text-align: right;
  padding: 0 40px;
}

.caption-banner .overlay {
  position: relative;
  z-index: 2;
}

.caption-text h2 {
  font-weight: normal;
  text-transform: capitalize;
}

.caption-banner.style-2 .caption-text {
  text-align: left;
}

/* Services Info */

.servise-detail-text {
  overflow: hidden;
}

.servise-detail-text h2 {
  font-size: 32px;
}

.signatur-list li {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 40px;
  float: left;
  margin: 0 0 0 40px;
}

.signatur-list li:first-child {
  margin: 0;
}

.group-img {
  position: relative;
}

.group-img::before,
.group-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 70%;
  width: 100%;
  margin: 100px 0 0;
}

.group-img::before {
  border: 10px solid #e1e1e1;
  z-index: -1;
  border-bottom: 0;
}

.group-img::after {
  border-bottom: 10px solid #e1e1e1;
}

.group-img.style-2::before,
.group-img.style-2::after {
  display: none;
}

/* Why Chose Us */

.accordion {
  height: auto;
}

.accordion>div {
  cursor: pointer;
}

.accordion-item {
  margin: 0 0 20px;
}

.accordion-item:last-child {
  margin: 0;
}

.accordion h4 {
  padding: 14.2px 15px;
  background: #f5f5f5;
  color: #222;
  overflow: hidden;
  border-right: 5px solid #ccc;
  margin: 0;
}

.accordion-marker {
  float: left;
  color: #9aa7af;
}

.accordion-marker i {
  margin: 0 10px 0 0;
}

.accordion .accordian-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms;
  border-right: 5px solid transparent;
}

.accordion .accordian-content p {
  padding: 0 37px 15px;
  font-weight: 600;
}

.accordion .accordian-content p:last-child {
  margin: 0;
}

.accordion>div.active .question {
  color: #000;
}

.accordion>div.active h4 {
  color: $white;
  border-color: $secondary-color;
}

.accordion>div.active .accordion-marker .fa-minus {
  color: $white;
}

.accordion>div.active .accordian-content {
  max-height: 200px;
  border-color: $secondary-color;
}

.accordion>div.active .accordian-content p {
  color: $white;
}

.accordion>div.active .accordion-marker i {
  color: $white;
  font-family: fontawesome;
}

/* We Do Best */

.we-do-best ul li {
  position: relative;
  padding-left: 100px;
  margin: 0 0 30px;
}

.we-do-best ul li:last-child {
  margin: 0;
}

.we-do-best ul li span {
  height: 82px;
  width: 82px;
  text-align: center;
  font-size: 36px;
  line-height: 80px;
  display: block;
  border: 2px solid #ccc;
  color: #222;
  position: absolute;
  left: 0;
  top: 0;
}

.we-do-best ul li:hover span {
  color: $white;
}

.we-do-best ul li .detail h4 {
  margin: 0 0 17px;
}

.we-do-best ul li .detail p:last-child {
  margin: 0;
}

/* Contact Banner */

.contact-detail h2 {
  text-transform: capitalize;
}

.contact-detail p {
  font-size: 22px;
}

.contact-detail .btn {
  font-size: 36px;
  padding-right: 90px;
}

.contact-detail .btn i {
  line-height: 70px;
  padding: 0 20px;
}

.consultation-banner .form-control {
  border-left: 5px solid;
}

/*** Contact BLOCK Pills ***/

.nav>li>a {
  position: relative;
  display: block;
  padding: 14px 45px;
  margin-bottom: 15px;
  font-size: 20px;
  background-color: $primary-color;
  color: $white;
  border-left: 8px solid darken($primary-color, 40%);
  border-radius: 0;
  margin-right: 2em;
  @media(min-width: 480px) {
    margin-bottom: -14px;
    padding: 14px 45px;
  }
}

.nav-pills>li:first-child {
  margin-left: 2px;
  @media(min-width: 480px) {
    margin-left: 2em;
  }
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: $white;
  background-color: $secondary-color-dark;
}

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: $secondary-color;
}

.nav-pills>li {
  float: initial;
  @media(min-width: 480px) {
    float: left;
  }
}

/*** Contact Page Pills ***/

.asterisk {
  color: #ff0000;
}

.contact-page {
  .nav>li>a {
    position: relative;
    display: block;
    padding: 14px 45px;
    margin-bottom: 10px;
    font-size: 20px;
    background-color: $primary-color;
    color: $white;
    margin-right: 2em;
    width: 100%;
    @media(min-width: 480px) {
      width: 12em;
    }
  }
  ul.nav {
    margin-bottom: 2em;
  }
  .nav-pills>li:first-child {
    margin-left: 0;
  }
  .nav-pills>li.active>a,
  .nav-pills>li.active>a:focus,
  .nav-pills>li.active>a:hover {
    color: $white;
    background-color: $secondary-color;
  }
  .nav>li>a:focus,
  .nav>li>a:hover {
    text-decoration: none;
    background-color: $secondary-color;
  }
  .nav.nav-pills {
    margin-top: 0;
    @media(min-width: 480px) {
      margin-top: 3em;
    }
  }
}

.nav.nav-pills {
  margin-top: 0;
  @media(min-width: 480px) {
    margin-top: 5em;
  }
}

div.office_contact {
  text-align: center;
  padding-top: 2em;
  @media (min-width: 990px) {
    display:none;
  }
}

/* Mission Statement Banner */

section.mission-statement-banner {
  box-shadow: -4px 0px 11px rgba(0, 0, 0, .8);
  border-top: 20px solid #88B24B;
  border-bottom: 20px solid #88B24B;
}

.mission-statement-banner h3 {
  font-size: 26px;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
  font-style: italic;
  color: $primary-color;
}

div.map {
  box-shadow: -4px 0px 11px rgba(0, 0, 0, .3);
}

/* Blog Post */

.post-item {
  padding: 0 100px;
}

.blog-post-detail {
  padding: 15px 0;
}

.meta-post {
  margin: 0 0 20px;
  overflow: hidden;
}

.meta-post li {
  position: relative;
  color: #999;
  padding: 0 0 0 20px;
  float: left;
  margin: 0 0 0 20px;
}

.meta-post li:first-child {
  margin: 0;
}

.meta-post li i {
  position: absolute;
  left: 0;
  top: 3px;
}

.blog-post-slider .owl-nav {
  margin: 0;
}

.blog-post-slider .owl-controls {
  position: absolute;
  top: 50%;
  width: 100%;
}

.blog-post-slider .owl-prev,
.blog-post-slider .owl-next {
  position: absolute;
  top: 50%;
  margin: -30px 0 0;
}

.blog-post-slider .owl-prev {
  left: 0;
}

.blog-post-slider .owl-next {
  right: 0;
}

.submit-Consultation .btn {
  margin: 30px 0 0;
}

.blog-post-slider-2 .post-item {
  padding: 0;
}

.blog-post-slider-2 .owl-controls {
  text-align: center;
  line-height: 0;
}

.blog-post-slider-2 .owl-nav {
  margin: 30px 0 0;
  display: inline-block;
}

/* Attorney */

.attorney-detail h3 {
  margin: 0 0 10px;
}

.attorney-detail span {
  display: block;
  margin: 0 0 10px;
}

.attorney-detail p {
  margin: 0 0 15px;
}

.address-links {
  padding: 14px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.address-links p {
  color: #222;
  font-weight: bold;
}

.attorney-slider .owl-controls {
  text-align: center;
  line-height: 0;
}

.attorney-slider .owl-dots {
  display: inline-block;
  margin: 40px 0 0;
}

.owl-dots .owl-dot {
  height: 10px;
  width: 10px;
  background: #ccc;
  float: left;
  margin: 0 0 0 10px;
  border-radius: 100%;
  cursor: pointer;
}

.owl-dots .owl-dot:first-child {
  margin: 0;
}

.owl-dots .owl-dot.active {
  width: 20px;
  border-radius: 50px;
}

/* Testimonial */

.testimonial-column {
  position: relative;
}

.client-review {
  padding: 30px 30px 30px 75px;
  background: $white;
  position: relative;
  margin: 0 110px 0 0;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
}

.client-review::before {
  content: "\f10d";
  font-family: fontawesome;
  position: absolute;
  left: 20px;
  top: 25px;
  font-size: 36px;
  color: #ccc;
}

.client-review h4 {
  margin: 0;
}

.client-review:after {
  content: "\f0da";
  font-family: fontawesome;
  font-size: 25px;
  top: 50%;
  margin: -15px 0 0;
  position: absolute;
  left: 100%;
  color: $white;
}

.client-img {
  right: 0;
  border-radius: 100%;
  overflow: hidden;
  border: 3px solid $white;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
}

.testimonial-slider .owl-controls {
  display: none;
}

.testimonial-slider .owl-item {
  padding: 2px 0;
}

.testimonial-slider .owl-item img {
  transform-style: initial !important;
}

/* Brands Icons */

.brands-icons-sliedr {
  border: 1px solid #ccc;
  border-right: 0;
  height: 131px;
}

.brands-icons-sliedr li {
  border-right: 1px solid #ccc;
  text-align: center;
  padding: 30px 0;
}

.brands-icons-sliedr.style-2 {
  height: auto;
}

.brands-icons-sliedr.style-2 li {
  padding: 0;
}

.brands-icons-sliedr.style-2 li a {
  opacity: 0.5;
}

.brands-icons-sliedr.style-2 li a:hover {
  opacity: 1;
}

/****** Footer ******/

.footer {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.footer::before {
  z-index: 1;
}

/* footer Columns */

.footer-columns {
  margin: 0 0 40px;
}

.about-legal a {
  font-weight: bold;
  font-size: 16px;
}

.practice-areas ul li {
  margin: 0 0 10px;
}

.practice-areas ul li:last-child {
  margin: 0;
}

.practice-areas ul li a {
  font-size: 16px;
  font-weight: 600;
  color: $white;
}

.practice-areas ul li a::before {
  content: "\f0da";
  font-family: fontawesome;
  margin: 0 10px 0 0;
}

.latest-tweets ul li {
  color: $white;
  padding-bottom: 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid;
  font-weight: bold;
  line-height: 24px;
}

.latest-tweets ul li:last-child {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.latest-tweets ul li i {
  margin: 0 10px 0 0;
}

.office-hours ul li {
  margin: 0 0 8px;
  overflow: hidden;
  font-weight: 600;
  color: $white;
}

.office-hours ul li:last-child {
  margin: 0;
}

.office-hours ul li span:first-child {
  float: left;
}

.office-hours ul li span:last-child {
  float: right;
}

.address-list {
  overflow: hidden;
  padding: 30px 0;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.address-list ul li {
  padding-left: 30px;
  position: relative;
  width: 33%;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
  color: $white;
}

.address-list ul li i {
  position: absolute;
  left: 0;
  top: 3px;
}

.sub-footer {
  overflow: hidden;
  padding: 20px 0;
}

.sub-footer .social-icons {
  float: right;
}

.sub-footer p {
  margin: 0;
  float: left;
  color: #999;
  font-weight: 600;
}

/****** Practice Areas v1 ***********************************************************
*********************************************************** Practice Areas v1 ******/

/* Inner Banner */

.page-heading h1,
.page-heading h2 {
  text-transform: capitalize;
  margin: 0;
}

/* practice Grid */

.practice-grid .practice-column {
  margin: 0 0 30px;
}

.pagination-holder .pagination {
  margin: 0;
}

/****** Practice Areas v2 ***********************************************************
*********************************************************** Practice Areas v2 ******/

.practice-column.style-2 .practice-column-btm {
  padding-left: 0;
}

/****** Practice Areas v3 ***********************************************************
*********************************************************** Practice Areas v3 ******/

.practice-column.style-3 .practice-column-btm {
  padding: 30px 0;
}

/****** Practice single ***********************************************************
*********************************************************** Practice single ******/

.practice-single-img {
  margin: 0 0 30px;
}

.practice-detail blockquote {
  margin: 0;
  padding: 0 100px 0 160px;
  margin: 0 0 30px;
}

.practice-detail blockquote q {
  font-size: 18px;
  color: #555;
  font-style: italic;
  font-weight: bold;
  position: relative;
}

.practice-detail blockquote q::before {
  content: "\f10d";
  font-size: 48px;
  font-style: normal;
  position: absolute;
  left: -65px;
  top: -8px;
  font-family: fontawesome;
}

.check-list {
  margin: 0 0 30px;
}

.check-list li {
  position: relative;
  padding: 0 0 0 30px;
  margin: 0 0 5px;
  font-weight: 600;
}

.check-list li:first-child {
  margin: 0;
}

.check-list li::before {
  content: "\f046";
  font-family: fontawesome;
  position: absolute;
  left: 0;
  font-weight: normal;
}

.post-aurthor {
  position: relative;
  overflow: hidden;
  padding-left: 195px;
}

.post-aurthor img {
  position: absolute;
  left: 0;
  top: 0;
}

.post-aurthor .aurthor-detail {
  padding: 15px 0;
}

.free-consultation-form .btn {
  margin: 30px 0 0;
  float: right;
}

/****** Gallery v1 v2 v3 ***********************************************************
*********************************************************** Gallery v1 v2 v3 ******/

.gallary.style-1 .gallary-figure {
  margin: 0 0 30px;
  overflow: hidden;
}

.gallary-figure {
  position: relative;
}

.gallary-figure img {
  width: 100%;
}

.gallary-figure .overlay {
  position: static;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
}

.gallary-figure:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.gallary-figure:hover .overlay-padding {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
}

.gallary-figure:hover .a-link {
  margin: 0;
}

/****** Attorney Detail ***********************************************************
*********************************************************** Attorney Detail ******/

.attorney-column.style-3 {
  padding: 0 40px;
}

.profiler-detail {
  position: relative;
}

.personal-detail h3 {
  margin-bottom: 0;
}

.personal-detail span {
  display: block;
  padding-bottom: 1.5em;
}

.personal-detail {
  padding: 40px;
  background: $white;
  max-width: 600px;
  right: 0;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);

  @media (min-width: 1200px) {
    min-width: 600px;
  }

}

.personal-detail-btm {
  overflow: hidden;
}

.personal-detail-btm p {
  float: left;
  margin: 0;
}

.personal-detail-btm .social-icons {
  float: right;
  margin: 5px 0 0;
}

/* Progress Bar */

.tc-skillgroup h4 {
  margin: 0;
}

.tc-skillgroup {
  float: left;
  width: 100%;
}

.tc-skill {
  float: left;
  width: 100%;
  margin: 0 0 30px;
}

.tc-skill:last-child {
  margin: 0;
}

.tc-skillname {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 0 0 10px;
}

.tc-skillholder {
  float: left;
  width: 100%;
  position: relative;
}

.tc-skillbar {
  height: 15px;
  float: left;
  transition: none;
  position: relative;
  background: $secondary-color;
}

.tc-per {
  right: -22px;
  top: -38px;
  height: 30px;
  width: 45px;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  line-height: 30px;
}

.tc-per::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin: 0 0 0 -5px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
}

.contact-width-attorney .btn {
  margin: 30px 0 0;
}

/****** Attorney v1 ***********************************************************
*********************************************************** Attorney v1 ******/

.attorney-column.style-1 {
  margin: 0 0 30px;
}

/****** Attorney v2 ***********************************************************
*********************************************************** Attorney v2 ******/

.attorney-column.styel-2 {
  margin: 0 0 30px;
}

.attorney-column.styel-2 .attorney-detail {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}

.attorney-column.styel-2 .address-links {
  padding: 0;
  border: 0;
}

.attorney-detail h3 {
  margin-bottom: 0;
}

.attorney-detail span {
  display: block;
  padding-bottom: 1em;
}

.attorney-clumn.style-3 {
  padding: 0 100px;
}

.attorney-clumn.style-3 .profiler-detail {
  margin: 0 0 30px;
}

.attorney-clumn.style-3 .profiler-detail:last-child {
  margin: 0;
}

.profiler-detail.left-style .personal-detail {
  left: 0;
  right: auto;
}

/****** Blog List ***********************************************************
*********************************************************** Blog List ******/

.blog-list .post-list-widget {
  margin: 0 0 30px;
}

/****** Blog Grid ***********************************************************
*********************************************************** Blog List ******/

.blog-list-grid .post-grig-widget {
  margin: 0 0 30px;
}

.blog-list-grid .blog-post-detail {
  padding-bottom: 0;
}

/****** Blog Full Width ***********************************************************
*********************************************************** Blog Full Width ******/

.blog-full-width .post-full-widget {
  margin: 0 0 30px;
}

.blog-full-width .blog-post-detail {
  padding-bottom: 0;
}

/* Slider Post */

.slider-post .owl-controls .owl-prev,
.slider-post .owl-controls .owl-next {
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  background: none;
  border-color: $white;
}

.slider-post .owl-controls .owl-prev {
  left: 30px;
}

.slider-post .owl-controls .owl-next {
  right: 30px;
}

.slider-post .owl-controls .owl-prev:hover,
.slider-post .owl-controls .owl-next:hover {
  background: $white;
  border-color: $white;
}

/* Video Post */

.video-post iframe {
  height: 450px;
}

/****** About Us ***********************************************************
*********************************************************** About Us ******/

.our-history p:last-child {
  margin: 0;
}

.history-video p {
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
}

.history-video img {
  width: 100%;
}

.service-column {
  text-align: center;
}

.service-column i {
  font-size: 60px;
  margin: 0 0 20px;
}

.service-column h3 {
  position: relative;
  padding: 0 0 20px;
}

.service-column h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  border: 1px solid;
  width: 60px;
  left: 50%;
  margin-left: -33px;
}

.service-column:last-child {
  margin: 0;
}

.video {
  position: relative;
}

.video .overlay {
  background: rgba( 0, 0, 0, 0.5);
}

.video a {
  font-size: 80px;
  color: $white;
}

.device-img {
  position: relative;
}

.device-img img {
  position: absolute;
}

/****** Contact ***********************************************************
*********************************************************** Contact ******/

.contact-map {
  height: 500px;
}

.address-list.style-2 {
  background: $white;
  border: 0;
  padding: 30px;
  margin: -55px 0 0;
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.34);
}

.address-list.style-2 ul li {
  color: #777;
}

.contact-form .btn {
  margin: 30px 0 0;
}

.testimonial-column-style-2 {
  position: relative;
  padding: 50px 40px 40px;
  border: 2px solid #ccc;
  overflow: hidden;
}

.testimonial-column-style-2::before {
  content: "\f10d";
  font-family: fontawesome;
  position: absolute;
  left: 20px;
  top: 0;
  font-size: 72px;
  color: #efe7cf;
  z-index: -1
}

.testimonial-column-style-2 h4 {
  float: left;
  margin: 24px 0 0;
}

.testimonial-column-style-2 h4 span {
  display: block;
  font-size: 14px;
  color: #666;
}

.testimonial-column-style-2 .client-img {
  float: left;
  margin: 0 20px 0 0;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

/* Aside */

.aside-widget {
  margin: 0 0 40px;
}

.aside-widget::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.aside-widget h3 {
  font-weight: normal;
  text-transform: capitalize;
  margin: 0 0 25px;
}

.search-bar {
  overflow: hidden;
  margin: 0 10px 0 0;
  position: relative;
}

.search-bar .form-control {
  font-size: 12px;
  border: 1px solid #e1e1e1;
  width: auto;
  min-width: 245px;
}

.search-bar button {
  border: 0;
  background: none;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  border-left: 1px solid #e1e1e1;
  padding: 0 15px;
}

.aside-widget .search-bar {
  margin: 0;
}

.aside-widget .search-bar button {
  color: $white;
}

.recent-post-widget .news-post {
  margin: 0 0 30px;
}

.recent-post-widget .news-post .detail h5 {
  font-size: 16px;
  margin: 0 0 10px;
}

.recent-post-widget .news-post .detail .meta-post {
  margin: 0;
  line-height: 14px;
}

.recent-post-widget .meta-post ul li {
  float: left;
  color: #999;
  margin: 0;
  padding: 0;
}

.recent-post-widget .meta-post ul li::before {
  content: "|";
  float: left;
  margin: 0 10px;
}

.recent-post-widget .meta-post ul li:first-child::before {
  display: none;
}

.recent-post-widget .news-post figure {
  margin: 0 0 10px;
}

.recent-post-widget .news-post .detail h5 {
  line-height: 23px;
}

.tag-cloud {
  margin: 0 0 0 -5px;
}

.tag-cloud a {
  float: left;
  font-size: 12px;
  border: 2px solid #e1e1e1;
  height: 35px;
  line-height: 31px;
  text-align: center;
  padding: 0 15px;
  margin: 0 0 5px 5px;
}

.tag-cloud a:hover {
  color: $white;
}

.categories-list ul li a {
  border-top: 1px dashed #ccc;
}

.categories-list ul li:last-child a {
  border-bottom: 1px dashed #ccc;
}

.categories-list ul li a {
  padding: 15px 0;
}

.categories-list ul li a::before {
  content: "\f105";
  float: left;
  margin: 0 10px 0 0;
  font-family: fontawesome;
  vertical-align: middle;
}

.categories-list ul li a:hover {
  color: $white;
  padding-left: 20px;
}

.recent-gallery ul {
  margin: -2.5px;
  overflow: hidden;
}

.recent-gallery ul li {
  margin: 2.5px;
  float: left;
}

.contact-form button {
  margin: 30px 0 0;
}

.contact-form .btn.icon i {
  padding-top: 1em;
}

.contact-form div.g-recaptcha {
  margin: 30px 0 0;
}

button input[type="submit"] {
  padding: 0;
  margin: 0;
  color: $white;
  background-color: $secondary-color-dark;
  border: none;
  transition: all 0.4s ease-in-out;
}

button:hover input[type="submit"] {
  color: #000;
  background-color: $secondary-color;
}

.margin13 {
  margin-top: 1.3em;
}

.sisea-result a {
  display: inline;
}